import React from 'react'
import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
import { Box, Typography } from '@material-ui/core'
import ResourcesModalForm from 'components/ResourcesModalForm'
import { formatISO } from 'date-fns'
import { Alert } from '@material-ui/lab'
const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/`

const SCRAPPER_LOCATION = `${BASE_URL}/api/sii_scraper/`

export const resourceKey = 'df_business'
export const collectionKey = 'df_businesses'

//REVISAR SCRAPEO DE DF_BUSINESS
function ScrapperRun({ resource }) {
}

export const fields = [
    { name: 'Nombre', field: 'name' },
    { name: 'Usuario', field: 'df_user' },
    { name: 'ID Cliente', field: 'df_client_id' },
    { name: 'ID empresa', field: 'df_business_id' },
    { name: 'Clave', field: 'df_password', only: 'create' },
    // { name: 'Token', field: 'provider', only: 'create' },
]

export const create = async (business) => {
    business.provider = 'defontana'
    console.log('Datos antes de enviar (DFcreate):', business) // Imprime los datos en consola
    return axios
        .post(RESOURCE_LOCATION, {
            business,
        })
        .then(dataGetter)
        .catch((e) => {
            console.error('Error en create:', e.response)
            throw e.response
        })
}


export const all = () => {
    console.log('Iniciando la solicitud a:', RESOURCE_LOCATION)

    return axios.get(RESOURCE_LOCATION)
        .then(response => {
            const data = response.data.data
            const filteredData = data.filter(item => item.provider === 'defontana')
            return filteredData
        })
        .then(data => {
            return data
        })
        .catch(error => {
            console.error('Error capturado en el bloque catch:', error)
            throw error
        })
}


export const edit = (business_id, business) => {
    business.provider = 'defontana'

    console.log('Datos a enviar:', { business_id, business })

    return axios
        .put(`${RESOURCE_LOCATION}/${business_id}`, {
            business,
        })
        .then(dataGetter)
        .catch((error) => {
            console.error('Error en edit:', error.response || error.message)
            throw error
        })
}

export const remove = (df_business_id) =>
    axios.delete(`${RESOURCE_LOCATION}/${df_business_id}`).then(dataGetter)

