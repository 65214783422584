import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import GraphCardMilkByDay from './GrpahCardMilkByDay'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { dailyDairyByPdIds } from '../../utils/api/models/MilkReports'
import { makeStyles } from '@material-ui/core/'
import es from 'date-fns/locale/es'
import { format, startOfDay, endOfDay } from 'date-fns'
import FilterMilkByDay from './FilterMilkbyDay'
import { getDateCalendarUtilityClass } from '@mui/x-date-pickers'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px',
  },
  indicadoresRoot: {
    width: '100%',
  },
  heading: {
    //marginLeft: '25px',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
}))

function filterByKey(key, id, data) {
  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date))
  //console.log(sortedData)
  const nivoData = { id: id, data: [] }
  sortedData.forEach((row, index) => {
    nivoData.data.push({
      x: row.date.slice(0, 23),
      y: row[key],
    })
  })
  return nivoData
}

function filterByKeyBoxPlot(key, id, data) {
  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date))
  //console.log(sortedData)
  const nivoData = []
  sortedData.forEach((row, index) => {
    nivoData.push({
      group: format(new Date(row.date), 'yyyy-MM'),
      subgroup: id,
      value: row[key],
    })
  })
  return nivoData
}

const medidas = [
  {
    name: 'Medición de estanque',
    dataColumn: 'pond_measurement',
  },
  {
    name: 'Medición de camión',
    dataColumn: 'truck_measurement',
  },
]

const datos_leche = [
  {
    name: 'Entrega a planta',
    dataColumn: 'truck_milk',
  },
  {
    name: 'Leche descarte',
    dataColumn: 'discard_milk',
  },
  {
    name: 'Leche personal',
    dataColumn: 'staff_milk',
  },
  {
    name: 'Leche ternero',
    dataColumn: 'calf_milk',
  },
]

const indicadores_rebano = [
  {
    name: 'Vacas en ordeña',
    dataColumn: 'milking_cows',
  },
  {
    name: 'Ingreso vacas nuevas',
    dataColumn: 'cow_entry',
  },
  {
    name: 'Vacas a secado',
    dataColumn: 'drying_cows',
  },
  {
    name: 'Vacas lote 1',
    dataColumn: 'cows_per_herd',
  },
  {
    name: 'Vacas lote 2',
    dataColumn: 'cows_per_herd_2',
  },
]

export default function AnalyticsMilkByDay() {
  const [fetchData, setFetchData] = useState({})
  const [fetchValidator, setFetchValidator] = useState(false)
  const [intervalDate, setIntervalDate] = useState({
    from: new Date(),
    to: new Date(),
  })
  const classes = useStyles()
  const locale = es

  async function getData(date_from, date_to, productive_units_ids) {
    setIntervalDate({ from: date_from, to: date_to })

    const data = await dailyDairyByPdIds(
      startOfDay(date_from),
      endOfDay(date_to),
      productive_units_ids,
    )

    let storage = {}
    data.forEach((row) => {
      let pd = row['productive_unit_id']

      if (storage[pd]) {
        storage[pd]['data'].push(row)
      } else {
        storage[pd] = {data: [row], name: `${row['productive_unit_name']} - ${row['farm_name']}`}
      }
    })

    setFetchData(storage)
    setFetchValidator(true)
  }

  return (
    <main>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h1' className={classes.heading}>
            Filtros
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FilterMilkByDay type_date='day' getData={getData} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      { Object.keys(fetchData).length === 0 && fetchValidator === true &&
        <ExpansionPanel>
          <ExpansionPanelSummary >
            <Typography variant='h1' className={classes.heading}>
              No hay datos para los parámetros seleccionados
            </Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      }
      {Object.keys(fetchData).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Datos de rebaño
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {indicadores_rebano.map((indicador, i) => {
                return (
                  <div key={`indacdor-rebaño-dia-${i}`}>
                    <Indicadores
                      intervalDate={intervalDate}
                      data={fetchData}
                      dataColumn={indicador.dataColumn}
                      name={indicador.name}
                    />
                  </div>
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {Object.keys(fetchData).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Datos de leche
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {datos_leche.map((indicador, i) => {
                return (
                  <div key={`indacdor-leche-dia-${i}`}>
                    <Indicadores
                      intervalDate={intervalDate}
                      data={fetchData}
                      dataColumn={indicador.dataColumn}
                      name={indicador.name}
                    />
                  </div>
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {Object.keys(fetchData).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Medidas
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {medidas.map((indicador, i) => {
                return (
                  <div key={`indacdor-medidas-dia-${i}`}>
                    <Indicadores
                      intervalDate={intervalDate}
                      data={fetchData}
                      dataColumn={indicador.dataColumn}
                      name={indicador.name}
                    />
                  </div>
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </main>
  )
}

function Indicadores({ dataColumn, name, data = {}, intervalDate }) {
  const classes = useStyles()

  let curve = []
  Object.entries(data).forEach(([key, value]) => {
    curve.push(filterByKey(dataColumn, value.name, value.data))
  })

  let box_plot = []
  Object.entries(data).forEach(([key, value]) => {
    box_plot.push(filterByKeyBoxPlot(dataColumn, value.name, value.data))
  })
  box_plot = _.flatten(box_plot)
  const box_plot_sorted_array = _.sortBy(box_plot, 'group')

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h3'>{name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GraphCardMilkByDay
              intervalDate={intervalDate}
              data={curve}
              data_box={box_plot_sorted_array}
              title={`${name} - Curva diaria`}
              data_type={['curve', 'boxplot', 'table']}
            />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
