import React, { useState, useEffect } from 'react'
import { Grid, Paper, Box, Tabs, Tab, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { Business } from 'utils/api/models'
import { Df_business } from 'utils/api/models'
import ResourcesAsTable from './ResourcesAsTable'
import withOwnerParam from 'hoc/withOwnerParam'
import * as actions from 'actions'


const Businesses = () => {
  const [businessesData, setBusinessesData] = useState()
  const [df_businessesData, setDfBusinessesData] = useState()
  const [activeTab, setActiveTab] = useState(0)
  const routeParams = useSelector((state) => state.routeParams)
  const dispatch = useDispatch()

  async function getBusinessData() {
    const businesses = await Business.all()
    setBusinessesData(businesses)
    dispatch(actions.breadcrumbResourcesUpdated({ farmOwnerId: true }))
  }

  useEffect(() => getBusinessData(), [
    routeParams.userId,
    routeParams.farmOwnerId,
  ])


  async function getDfBusinessData() {
    const df_businesses = await Df_business.all()
    setDfBusinessesData(df_businesses)
    console.log('dentro del componente, los datos DF son:', df_businesses)
    dispatch(actions.breadcrumbResourcesUpdated({ farmOwnerId: true }))
  }

  useEffect(() => getBusinessData(), [routeParams.userId, routeParams.farmOwnerId])
  useEffect(() => getDfBusinessData(), [routeParams.userId, routeParams.farmOwnerId])



  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue) // Cambiar la pestaña activa
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
          >
            <h2> Administrar empresas </h2>
          </Box>


          {/* Contenedor de las pestañas con 100% de ancho */}
          <Box width='100%' marginBottom='10px'>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
              centered
              style={{ width: '100%' }} // Asegura que el contenedor ocupe todo el ancho
            >
              {/* Pestañas con 50% de ancho */}
              <Tab label="SII" style={{ width: '50%' }} />
              <Tab label="Defontana" style={{ width: '50%' }} />
            </Tabs>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          position='relative'
          height='80vh'
        >
          {activeTab === 0 && businessesData ? (
            <ResourcesAsTable
              resources={businessesData}
              model={Business}
              title="Empresa SII"
              refreshData={async () => await getBusinessData()}
              setSelected={(id) => console.log(id)}
              parentSelections={[]}
              businessSelectionEnabled={true}
            />
          ) : activeTab === 1 && df_businessesData ? (
            // 
            <ResourcesAsTable
              resources={df_businessesData}
              model={Df_business}
              title="Empresa Defontana"
              refreshData={async () => await getDfBusinessData()}
              setSelected={(id) => console.log(id)}
              parentSelections={[]}
              businessSelectionEnabled={true}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Businesses